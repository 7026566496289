.box{
    border-radius: 29px;
    background: #e6e6e6;
    box-shadow:  10px 10px 20px #969696,
                -10px -10px 20px #ffffff;
}

.title {
    color: "var(--dark-gray)";
    font-size: "2rem";
}
.title:hover {
    cursor: pointer;
}