:root{
  --dark-gray: #343A40;
  --light-gray: #3D434A;
  --primary: #6a9596;
  --off-white: #e6e6e6;
}

body{
  margin: 0;
  background-color: var(--off-white);
}

.App {
  text-align: center;
}

